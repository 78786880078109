import { SolutionsDataType } from './solutionsData'
import clsx from 'clsx'
import classes from './LandingSolutions.module.scss'

export const LandingSolutionsCard = ({ item }: { item: SolutionsDataType }) => {
  const { title, imageMob, imageDesk } = item

  return (
    <li className={classes.item}>
      <div className={clsx('flex-column', classes.card)}>
        <h3 className={clsx('page__subtitle', classes.card_subtitle)}>
          {title}
        </h3>
        <div className={classes.card_visual}>
          <div className={clsx('page__glow', classes.card_glow)} />
          <picture className={classes.card_image}>
            <source media='(min-width: 768px)' srcSet={imageDesk.src} />
            <source media='(max-width: 767px)' srcSet={imageMob.src} />
            <img src={imageMob.src} alt={title} loading='lazy' />
          </picture>
        </div>
      </div>
    </li>
  )
}
