// import { Link } from 'routes/components/Shared'
// import { LINKS } from 'routes/constants'
import { solutionsData } from './solutionsData'
import { LandingSolutionsCard } from './LandingSolutionsCard'
import clsx from 'clsx'
import classes from './LandingSolutions.module.scss'

export const LandingSolutions = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.body)}>
        <h2 className={clsx('page__title', classes.title)}>
          Ship integration features of any complexity 10x faster
        </h2>

        <ul className={clsx('flex-column', classes.list)}>
          {solutionsData.map((item, index) => (
            <LandingSolutionsCard key={index} item={item} />
          ))}
        </ul>

        {/* <Link
          className={clsx('page__button', classes.button)}
          type='secondary'
          href={LINKS.SOLUTIONS}
        >
          Explore Solutions
        </Link> */}
      </div>
    </section>
  )
}
