import { LINKS, DOCS_LINK } from 'routes/constants'
import { Link, BookCalendlyButton } from 'routes/components/Shared'
import HeroVideoMobileMp4 from './landing-hero-mob.mp4'
import HeroVideoMobileWebm from './landing-hero-mob.webm'
import HeroImageMobile from './landing-hero-mob.png'
import HeroVideoDesktopMp4 from './landing-hero-desk.mp4'
import HeroVideoDesktopWebm from './landing-hero-desk.webm'
import HeroImageDesktop from './landing-hero-desk.png'
import clsx from 'clsx'
import classes from './LandingHero.module.scss'
import { Counter } from 'routes/Index/components/Hero/Counter'

export const LandingHero = () => {
  return (
    <section className={clsx('flex-column', classes.section)}>
      <div className={clsx('flex-column', classes.heading)}>
        <h1 className={classes.title}>
          <span className={clsx(classes.text, classes.text_grey)}>
            Integration platforms are too restrictive
          </span>
          <span className={clsx(classes.text, classes.text_white)}>
            Level up the integration infrastructure <br />
            of your SaaS product
          </span>
        </h1>

        <Link
          className={classes.link}
          type='noLineGradient'
          href={LINKS.HOW_WE_ARE_DIFFERENT}
        >
          How is it different from iPaaS and unified APIs?
        </Link>
      </div>

      <Actions />
      <HeroVideo />
    </section>
  )
}

const Actions = () => {
  return (
    <div className={clsx('page__actions', classes.actions)}>
      <Link
        className={clsx('page__button', classes.button, classes.button__try)}
        type='primary'
        href='https://console.integration.app/login'
      >
        Try For Free
      </Link>

      <Link
        className={clsx('page__button', classes.button, classes.button__read)}
        type='secondary'
        href={DOCS_LINK}
      >
        Read Documentation
      </Link>

      <BookCalendlyButton
        className={clsx('page__button', classes.button, classes.button__book)}
        category='secondary'
        isReversed
      >
        <span>Book a Demo</span>
      </BookCalendlyButton>
    </div>
  )
}

const HeroVideo = () => {
  return (
    <>
      <div className={classes.wrapper}>
        <div
          className={clsx('flex-column', classes.video, classes.video__desktop)}
        >
          <video
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            controls={false}
            poster={HeroImageDesktop.src}
          >
            <source src={HeroVideoDesktopWebm} type='video/webm' />
            <source src={HeroVideoDesktopMp4} type='video/mp4' />
            <img src={HeroImageDesktop.src} alt='' />
          </video>

          <Counter className={classes.video_counter} />
        </div>
      </div>
      <div className={clsx(classes.video, classes.video__mobile)}>
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          controls={false}
          poster={HeroImageMobile.src}
        >
          <source src={HeroVideoMobileWebm} type='video/webm' />
          <source src={HeroVideoMobileMp4} type='video/mp4' />
          <img src={HeroImageMobile.src} alt='' />
        </video>
      </div>
    </>
  )
}
